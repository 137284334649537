import type {ChangeEventHandler, FocusEventHandler, HTMLAttributes, HTMLInputTypeAttribute, JSX} from 'react';

type InputProps = {
  className?: string;
  name?: string;
  type?: HTMLInputTypeAttribute;
  inputMode?: HTMLAttributes<HTMLInputElement>['inputMode'];
  icon?: JSX.Element;
  label?: string;
  placeholder?: string;
  value?: string | number;
  maxLength?: number;
  isChecked?: boolean;
  onFocus?: FocusEventHandler<HTMLInputElement>;
  onChange?: ChangeEventHandler<HTMLInputElement>;
  onBlur?: FocusEventHandler<HTMLInputElement>;
};

const Input = ({
  className = '',
  name = '',
  type = 'text',
  inputMode = 'text',
  icon,
  label = '',
  placeholder = '',
  value = '',
  maxLength,
  isChecked = false,
  onFocus,
  onChange,
  onBlur
}: InputProps): JSX.Element => (
  <>
    {type === 'text' && (
      <input
        className={`w-full rounded-xl border-2 border-gray-200 px-3 py-2 text-sm outline-none md:text-base lg:text-xs ${className}`.trim()}
        name={name}
        type={type}
        inputMode={inputMode}
        placeholder={placeholder}
        value={value}
        maxLength={maxLength}
        autoComplete='off'
        onFocus={onFocus}
        onChange={onChange}
        onBlur={onBlur}
      />
    )}
    {type === 'radio' && (
      <label
        className={`flex cursor-pointer rounded-xl border-2 border-gray-200 p-3 text-sm duration-150 md:py-2 lg:text-xs ${isChecked ? '!border-default' : ''}`.trim()}
      >
        <input
          className='hidden'
          type={type}
          name={name}
          value={value}
          checked={isChecked}
          autoComplete='off'
          onChange={onChange}
        />
        <div className='flex items-center justify-center space-x-2'>
          {typeof icon !== 'undefined' && icon}
          <span>{label}</span>
        </div>
      </label>
    )}
  </>
);

export default Input;
