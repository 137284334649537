import {type Main, setMain as setFlavorsMain} from 'src/redux/reducers/flavors';
import {useDispatch, useSelector} from 'src/redux/hooks';

type UseApp = {
  main: Main;
  setMain: (main: Main) => void;
};

const useApp = (): UseApp => {
  const dispatch = useDispatch();

  const setMain = (main: Main): void => {
    dispatch(setFlavorsMain(main));
  };

  return {
    main: useSelector((state) => state.flavors.main),
    setMain: setMain
  };
};

export default useApp;
