import appReducer from 'src/redux/reducers/app';
import {combineReducers} from '@reduxjs/toolkit';
import flavorsReducer from 'src/redux/reducers/flavors';

const rootReducer = combineReducers({
  app: appReducer,
  flavors: flavorsReducer
});

export default rootReducer;
