import 'src/app.css';
import 'src/react-i18next';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import {Analytics, Language, Online, Viewport} from 'src/components/app';
import {type JSX, StrictMode, Suspense} from 'react';
import {BrowserRouter} from 'react-router-dom';
import {HelmetProvider} from 'react-helmet-async';
import {PersistGate} from 'redux-persist/integration/react';
import {Provider} from 'react-redux';
import Router from 'src/router';
import persistor from 'src/redux/persistor';
import store from 'src/redux/store';

const App = (): JSX.Element => (
  <StrictMode>
    <Suspense>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <Viewport>
            <Language>
              <Online>
                <HelmetProvider>
                  <BrowserRouter>
                    <Analytics />
                    <Router />
                  </BrowserRouter>
                </HelmetProvider>
              </Online>
            </Language>
          </Viewport>
        </PersistGate>
      </Provider>
    </Suspense>
  </StrictMode>
);

export default App;
