import type {JSX, ReactNode} from 'react';
import {
  Dialog as ReactDialog,
  DialogPanel as ReactDialogPanel,
  DialogTitle as ReactDialogTitle,
  Transition,
  TransitionChild
} from '@headlessui/react';
import {Button} from 'src/components/flavors';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import type {IconDefinition} from '@fortawesome/free-solid-svg-icons';

type DialogProps = {
  isVisible?: boolean;
  title?: string;
  icon?: IconDefinition;
  confirm?: string;
  children?: ReactNode;
  onClose?: (...args: unknown[]) => unknown;
  onConfirm?: (...args: unknown[]) => unknown;
};

const Dialog = ({
  isVisible = false,
  title = '',
  icon,
  confirm = '',
  children,
  onClose = (): void => {},
  onConfirm = (): void => {}
}: DialogProps): JSX.Element => (
  <Transition show={isVisible}>
    <ReactDialog onClose={onClose}>
      <TransitionChild>
        <div className='fixed left-0 top-0 z-10 h-dvh w-dvw bg-black/60 duration-300 data-[closed]:opacity-0'></div>
      </TransitionChild>
      <div className='fixed left-0 top-0 z-10 flex h-dvh w-dvw items-center justify-center'>
        <TransitionChild>
          <ReactDialogPanel className='mx-5 w-full space-y-4 rounded-xl bg-white p-5 text-gray-600 duration-300 data-[closed]:opacity-0 md:mx-auto md:w-2/5 lg:w-1/4 lg:space-y-3'>
            <ReactDialogTitle className='font-bold leading-none lg:text-sm'>{title}</ReactDialogTitle>
            {children}
            <Button className='space-x-2' onClick={onConfirm}>
              {typeof icon !== 'undefined' && <FontAwesomeIcon className='text-lg' icon={icon} />}
              <span>{confirm}</span>
            </Button>
          </ReactDialogPanel>
        </TransitionChild>
      </div>
    </ReactDialog>
  </Transition>
);

export default Dialog;
