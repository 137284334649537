import {type Middleware, type Tuple, configureStore} from '@reduxjs/toolkit';
import {getPersistConfig} from 'redux-deep-persist';
import {persistReducer} from 'redux-persist';
import rootReducer from 'src/redux/reducers';
import storage from 'src/redux/storage';

const persistConfig = getPersistConfig({
  key: 'root',
  storage,
  whitelist: ['flavors.main.search', 'flavors.main.category', 'flavors.main.itemDetail', 'flavors.main.cart'],
  rootReducer
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware): Tuple<Middleware[]> =>
    getDefaultMiddleware({
      serializableCheck: {
        ignoreActions: true
      }
    }),
  devTools: process.env.NODE_ENV !== 'production'
});

export type RootState = ReturnType<typeof store.getState>;
export type AppDispatch = typeof store.dispatch;
export default store;
