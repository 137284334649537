import {type JSX, type ReactNode, useEffect} from 'react';
import useApp from 'src/hooks/use-app';

type OnlineProps = {
  children?: ReactNode;
};

const Online = ({children}: OnlineProps): JSX.Element => {
  const {setIsOnline} = useApp();

  useEffect((): (() => void) => {
    const handleUpdateOnlineStatus = (): void => {
      setIsOnline(navigator.onLine);
    };
    handleUpdateOnlineStatus();
    window.addEventListener('online', handleUpdateOnlineStatus);
    window.addEventListener('offline', handleUpdateOnlineStatus);
    return (): void => {
      window.removeEventListener('online', handleUpdateOnlineStatus);
      window.removeEventListener('offline', handleUpdateOnlineStatus);
    };
  }, []);

  return <>{children}</>;
};

export default Online;
