import {type JSX, type ReactNode, useEffect} from 'react';
import useApp from 'src/hooks/use-app';

type LanguageProps = {
  children?: ReactNode;
};

const Language = ({children}: LanguageProps): JSX.Element => {
  const {language, setLanguage} = useApp();

  useEffect((): void => {
    const currentLanguage = localStorage.getItem('reshare:language');
    if (currentLanguage === null || !['id', 'en', 'jp'].includes(currentLanguage)) {
      localStorage.setItem('reshare:language', language);
    } else {
      setLanguage(currentLanguage as typeof language);
    }
  }, []);

  return <>{children}</>;
};

export default Language;
