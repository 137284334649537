import {type PayloadAction, createSlice} from '@reduxjs/toolkit';
import {DateTime} from 'luxon';
import type {Language} from 'src/redux/reducers/app';

type Main = {
  search: string;
  category: 'all' | 'toast' | 'crepe' | 'drinks';
  itemDetail: {
    id: string;
    quantity: number;
  } | null;
  cart: {
    isVisible: boolean;
    isViewing: boolean;
    items: {
      id: string;
      quantity: number;
    }[];
    shipping: {
      delivery: 'grabfood' | 'grabexpress' | 'gosend' | null;
      name: string;
      address: string;
    };
    billing: {
      subtotal: number;
      total: number;
    };
  };
  dialog: {
    language: {
      isVisible: boolean;
      value: Language;
    };
    clearCart: {
      isVisible: boolean;
    };
    shipping: {
      delivery: {
        isVisible: boolean;
        value: 'grabfood' | 'grabexpress' | 'gosend' | null;
      };
      name: {
        isVisible: boolean;
        value: string;
      };
      address: {
        isVisible: boolean;
        value: string;
      };
    };
  };
};

type State = {
  main: Main;
};

type Reducers = {
  setMain: (state: State, action: PayloadAction<Main>) => void;
};

const name = 'flavors';

const initialState: State = {
  main: {
    search: '',
    category: 'all',
    itemDetail: null,
    cart: {
      isVisible: false,
      isViewing: false,
      items: ((): Main['cart']['items'] => {
        const cart = localStorage.getItem('reshare:flavors:cart');
        try {
          if (cart === null) {
            throw new window.Error();
          }
          const {items, timestamp} = JSON.parse(cart);
          if (
            items.length === 0 ||
            DateTime.fromISO(timestamp) > DateTime.fromISO(timestamp).plus({hours: 12})
          ) {
            throw new window.Error();
          }
          return items;
        } catch {
          localStorage.removeItem('reshare:flavors:cart');
          return [];
        }
      })(),
      shipping: {
        delivery: null,
        name: '',
        address: ''
      },
      billing: {
        subtotal: 0,
        total: 0
      }
    },
    dialog: {
      language: {
        isVisible: false,
        value: 'id'
      },
      clearCart: {
        isVisible: false
      },
      shipping: {
        delivery: {
          isVisible: false,
          value: null
        },
        name: {
          isVisible: false,
          value: ''
        },
        address: {
          isVisible: false,
          value: ''
        }
      }
    }
  }
};

const reducers: Reducers = {
  setMain: (state, action) => {
    if (state.main !== action.payload) {
      state.main = action.payload;
    }
  }
};

const slice = createSlice({
  name: name,
  initialState: initialState,
  reducers: reducers
});

export {initialState};
export type {Main};
export const {setMain} = slice.actions;
export default slice.reducer;
