import {type JSX, useEffect} from 'react';
import googleAnalytics from 'react-ga4';
import {useLocation} from 'react-router-dom';

const Analytics = (): JSX.Element => {
  const location = useLocation();

  useEffect((): void => {
    googleAnalytics.send({hitType: 'pageview', page: location.pathname});
  }, [location]);

  return <></>;
};

export default Analytics;
