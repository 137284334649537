import {type PayloadAction, createSlice} from '@reduxjs/toolkit';

type IsOnline = boolean;
type Language = 'id' | 'en' | 'jp';
type Viewport = {
  width: number;
  height: number;
};

type State = {
  isOnline: IsOnline;
  language: Language;
  viewport: Viewport;
};

type Reducers = {
  setIsOnline: (state: State, action: PayloadAction<IsOnline>) => void;
  setLanguage: (state: State, action: PayloadAction<Language>) => void;
  setViewport: (state: State, action: PayloadAction<Viewport>) => void;
};

const name = 'app';

const initialState: State = {
  isOnline: false,
  language: 'id',
  viewport: {
    width: 0,
    height: 0
  }
};

const reducers: Reducers = {
  setIsOnline: (state, action) => {
    if (state.isOnline !== action.payload) {
      state.isOnline = action.payload;
    }
  },
  setLanguage: (state, action) => {
    if (state.language !== action.payload) {
      state.language = action.payload;
    }
  },
  setViewport: (state, action) => {
    if (state.viewport !== action.payload) {
      state.viewport = action.payload;
    }
  }
};

const slice = createSlice({
  name: name,
  initialState: initialState,
  reducers: reducers
});

export {initialState};
export type {IsOnline, Language, Viewport};
export const {setIsOnline, setLanguage, setViewport} = slice.actions;
export default slice.reducer;
