export const encrypt = async (plain: string, key: CryptoKey): Promise<{encrypted: string; iv: string}> => {
  const encoder = new TextEncoder();
  const iv = crypto.getRandomValues(new Uint8Array(12));
  const encrypted = await crypto.subtle.encrypt({name: 'AES-GCM', iv: iv}, key, encoder.encode(plain));
  return {
    encrypted: btoa(String.fromCharCode(...Array.from(new Uint8Array(encrypted)))),
    iv: btoa(String.fromCharCode(...Array.from(iv)))
  };
};

export const decrypt = async (key: CryptoKey, encrypted: string, iv: string): Promise<string> => {
  const decoder = new TextDecoder();
  const decrypted = await crypto.subtle.decrypt(
    {
      name: 'AES-GCM',
      iv: new Uint8Array(
        atob(iv)
          .split('')
          .map((character): number => character.charCodeAt(0))
      )
    },
    key,
    new Uint8Array(
      atob(encrypted)
        .split('')
        .map((character): number => character.charCodeAt(0))
    )
  );
  return decoder.decode(decrypted);
};

export const exportKey = async (sessionName: string, key: CryptoKey): Promise<void> => {
  const exportedKey = await crypto.subtle.exportKey('jwk', key);
  sessionStorage.setItem(sessionName, JSON.stringify(exportedKey));
};

export const generateKey = async (): Promise<CryptoKey> =>
  await crypto.subtle.generateKey({name: 'AES-GCM', length: 256}, true, ['encrypt', 'decrypt']);

export const importKey = async (sessionName: string): Promise<CryptoKey | null> => {
  const importedKey = sessionStorage.getItem(sessionName);
  return importedKey === null
    ? null
    : await crypto.subtle.importKey('jwk', JSON.parse(importedKey), {name: 'AES-GCM'}, true, [
        'encrypt',
        'decrypt'
      ]);
};
