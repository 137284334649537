import {Button, Dialog, Input, TextArea} from 'src/components/flavors';
import {type ChangeEvent, type JSX, useEffect, useRef, useState} from 'react';
import {decrypt, encrypt, exportKey, generateKey, importKey} from 'src/utils/crypto';
import {
  faArrowLeft,
  faBagShopping,
  faCartPlus,
  faCartShopping,
  faEdit,
  faEnvelope,
  faMinus,
  faPlus,
  faSave,
  faSearch,
  faTrashAlt
} from '@fortawesome/free-solid-svg-icons';
import {faInstagram, faTiktok, faWhatsapp} from '@fortawesome/free-brands-svg-icons';
import {Carousel} from 'react-responsive-carousel';
import {DateTime} from 'luxon';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import ReactCountryFlag from 'react-country-flag';
import {Seo} from 'src/components/shared';
import {Transition} from '@headlessui/react';
import bannerWelcomeEn from 'src/assets/images/banner-welcome-en.webp';
import bannerWelcomeId from 'src/assets/images/banner-welcome-id.webp';
import bannerWelcomeJp from 'src/assets/images/banner-welcome-jp.webp';
import catalogCrepeChocoBerry from 'src/assets/images/catalog-crepe-choco-berry.webp';
import catalogCrepeChocoBliss from 'src/assets/images/catalog-crepe-choco-bliss.webp';
import catalogCrepeChocoMatcha from 'src/assets/images/catalog-crepe-choco-matcha.webp';
import catalogCrepeChocoVanilla from 'src/assets/images/catalog-crepe-choco-vanilla.webp';
import catalogCrepeVanillaBerry from 'src/assets/images/catalog-crepe-vanilla-berry.webp';
import catalogCrepeVanillaBliss from 'src/assets/images/catalog-crepe-vanilla-bliss.webp';
import catalogCrepeVanillaChoco from 'src/assets/images/catalog-crepe-vanilla-choco.webp';
import catalogCrepeVanillaMatcha from 'src/assets/images/catalog-crepe-vanilla-matcha.webp';
import catalogDrinksChocolateMilk from 'src/assets/images/catalog-drinks-chocolate-milk.webp';
import catalogDrinksStrawberryMilk from 'src/assets/images/catalog-drinks-strawberry-milk.webp';
import catalogDrinksTea from 'src/assets/images/catalog-drinks-tea.webp';
import catalogDrinksThaiTeaMilk from 'src/assets/images/catalog-drinks-thai-tea-milk.webp';
import catalogToastCheese from 'src/assets/images/catalog-toast-cheese.webp';
import catalogToastChoco from 'src/assets/images/catalog-toast-choco.webp';
import catalogToastChocoCheese from 'src/assets/images/catalog-toast-choco-cheese.webp';
import googleAnalytics from 'react-ga4';
import {isMobile} from 'react-device-detect';
import pickupGojek from 'src/assets/images/pickup-gojek.webp';
import pickupGrab from 'src/assets/images/pickup-grab.webp';
import {toTitleCase} from 'src/utils/format';
import useApp from 'src/hooks/use-app';
import useFlavors from 'src/hooks/use-flavors';
import useTranslation from 'src/hooks/use-translation';

const Flavors = (): JSX.Element => {
  const itemsDivRef = useRef<HTMLDivElement | null>(null);
  const {i18next, translate} = useTranslation('flavors');
  const [itemScroll, setItemScroll] = useState<{isAtTop: boolean; isAtBottom: boolean}>({
    isAtTop: true,
    isAtBottom: true
  });
  const {language, viewport, setLanguage} = useApp();
  const {main, setMain} = useFlavors();
  const [shouldAnimate, setShouldAnimate] = useState<boolean>(true);

  const banners = [
    {
      name: 'welcome-id',
      src: bannerWelcomeId
    },
    {
      name: 'welcome-en',
      src: bannerWelcomeEn
    },
    {
      name: 'welcome-jp',
      src: bannerWelcomeJp
    }
  ];

  const languages = [
    {
      id: 'id',
      name: 'indonesian',
      country: 'ID'
    },
    {
      id: 'en',
      name: 'english',
      country: 'US'
    },
    {
      id: 'jp',
      name: 'japanese',
      country: 'JP'
    }
  ];

  const categories = ['all', 'toast', 'crepe', 'drinks'];

  const items = [
    {
      id: 'chocoToast',
      category: 'toast',
      image: catalogToastChoco,
      price: 15000,
      style: 'pt-3'
    },
    {
      id: 'cheeseToast',
      category: 'toast',
      image: catalogToastCheese,
      price: 15000,
      style: 'pt-10'
    },
    {
      id: 'chocoCheeseToast',
      category: 'toast',
      image: catalogToastChocoCheese,
      price: 18000,
      style: 'pt-8'
    },
    {
      id: 'chocoBlissCrepe',
      category: 'crepe',
      image: catalogCrepeChocoBliss,
      price: 15000,
      style: 'pt-6'
    },
    {
      id: 'chocoMatchaCrepe',
      category: 'crepe',
      image: catalogCrepeChocoMatcha,
      price: 15000,
      style: 'pt-8'
    },
    {
      id: 'chocoVanillaCrepe',
      category: 'crepe',
      image: catalogCrepeChocoVanilla,
      price: 15000,
      style: 'pt-6'
    },
    {
      id: 'chocoBerryCrepe',
      category: 'crepe',
      image: catalogCrepeChocoBerry,
      price: 15000,
      style: 'pt-4'
    },
    {
      id: 'vanillaBlissCrepe',
      category: 'crepe',
      image: catalogCrepeVanillaBliss,
      price: 15000,
      style: 'pt-4'
    },
    {
      id: 'vanillaChocoCrepe',
      category: 'crepe',
      image: catalogCrepeVanillaChoco,
      price: 15000,
      style: 'pt-2'
    },
    {
      id: 'vanillaMatchaCrepe',
      category: 'crepe',
      image: catalogCrepeVanillaMatcha,
      price: 15000,
      style: 'pt-8'
    },
    {
      id: 'vanillaBerryCrepe',
      category: 'crepe',
      image: catalogCrepeVanillaBerry,
      price: 15000,
      style: 'pt-6'
    },
    {
      id: 'plainTea',
      category: 'drinks',
      image: catalogDrinksTea,
      price: 5000,
      style: 'pt-10'
    },
    {
      id: 'sweetTea',
      category: 'drinks',
      image: catalogDrinksTea,
      price: 8000,
      style: 'pt-10'
    },
    {
      id: 'lemonTea',
      category: 'drinks',
      image: catalogDrinksTea,
      price: 15000,
      style: 'pt-10'
    },
    {
      id: 'lycheeTea',
      category: 'drinks',
      image: catalogDrinksTea,
      price: 15000,
      style: 'pt-10'
    },
    {
      id: 'mangoTea',
      category: 'drinks',
      image: catalogDrinksTea,
      price: 15000,
      style: 'pt-10'
    },
    {
      id: 'chocolateMilk',
      category: 'drinks',
      image: catalogDrinksChocolateMilk,
      price: 15000,
      style: 'pt-10'
    },
    {
      id: 'strawberryMilk',
      category: 'drinks',
      image: catalogDrinksStrawberryMilk,
      price: 15000,
      style: 'pt-14'
    },
    {
      id: 'thaiTeaMilk',
      category: 'drinks',
      image: catalogDrinksThaiTeaMilk,
      price: 15000,
      style: 'pt-2'
    }
  ];

  const socialMedias = [
    {
      name: 'whatsapp',
      icon: faWhatsapp,
      color: '!bg-[#25D366]',
      url: 'https://wa.me/6287784210510'
    },
    {
      name: 'instagram',
      icon: faInstagram,
      color: 'bg-gradient-to-r from-purple-500 via-pink-500 to-yellow-500',
      url: 'https://instagram.com/reshare_flavors'
    },
    {
      name: 'tiktok',
      icon: faTiktok,
      color: 'bg-gradient-to-r from-cyan-400 to-pink-500',
      url: 'https://tiktok.com/@reshare.flavors'
    },
    {
      name: 'email',
      icon: faEnvelope,
      color: '!bg-[#4682B4]',
      url: 'mailto:admin@reshare.online'
    }
  ];

  const deliveries = [
    {
      name: 'grabfood',
      icon: pickupGrab
    },
    {
      name: 'grabexpress',
      icon: pickupGrab
    },
    {
      name: 'gosend',
      icon: pickupGojek
    }
  ];

  const handleDisableAnimations = (): void => {
    setTimeout((): void => {
      setShouldAnimate(false);
    }, 1000);
  };

  const handleUpdateSearch = (event: ChangeEvent<HTMLInputElement>, format: 'blur' | null = null): void => {
    const {value} = event.target;
    const updatedValue = format === 'blur' ? value.trim() : value;
    if (main.search !== updatedValue) {
      setMain({
        ...main,
        search: updatedValue,
        ...(main.category !== 'all' && {category: 'all'})
      });
    }
  };

  const handleToggleConfirmLanguage = (isVisible: boolean): void => {
    if (main.dialog.language.isVisible !== isVisible) {
      setMain({
        ...main,
        dialog: {
          ...main.dialog,
          language: {
            ...main.dialog.language,
            isVisible: isVisible,
            ...(main.dialog.language.value !== language && {value: language})
          }
        }
      });
    }
  };

  const handleSelectLanguage = (event: ChangeEvent<HTMLInputElement>): void => {
    const {value} = event.target;
    if (main.dialog.language.value !== value) {
      setMain({
        ...main,
        dialog: {
          ...main.dialog,
          language: {
            ...main.dialog.language,
            value: value as typeof main.dialog.language.value
          }
        }
      });
    }
  };

  const handleSaveLanguage = (): void => {
    const newLanguage = main.dialog.language.value;
    if (language !== newLanguage) {
      setLanguage(newLanguage);
      localStorage.setItem('reshare:language', newLanguage);
    }
    setMain({
      ...main,
      dialog: {
        ...main.dialog,
        language: {
          ...main.dialog.language,
          isVisible: false
        }
      }
    });
  };

  const handleSelectCategory = (category: typeof main.category): void => {
    if (main.category !== category) {
      setMain({
        ...main,
        search: '',
        category: category
      });
    }
  };

  const handleUpdateScroll = (): void => {
    if (itemsDivRef.current !== null) {
      const {scrollTop, scrollHeight, clientHeight} = itemsDivRef.current;
      const isAtTop = scrollTop < 100;
      const isAtBottom = scrollTop + clientHeight >= scrollHeight - 100;
      setItemScroll({
        isAtTop: isAtTop,
        isAtBottom: isAtBottom
      });
    }
  };

  const handleToggleViewItemDetail = (itemId: string | null): void => {
    if (itemId !== null) {
      window.scrollTo({top: 0});
    }
    setMain({
      ...main,
      itemDetail:
        itemId === null
          ? null
          : {
              id: itemId,
              quantity: main.cart.items.find((cartItem): boolean => cartItem.id === itemId)?.quantity ?? 1
            },
      ...(main.cart.isVisible && {cart: {...main.cart, isViewing: true}})
    });
  };

  const handleDecrementItemDetailQuantity = (): void => {
    if (
      main.itemDetail !== null &&
      (main.itemDetail.quantity > 1 ||
        (main.cart.items.some((cartItem): boolean => cartItem.id === main.itemDetail?.id) &&
          main.itemDetail.quantity > 0))
    ) {
      setMain({
        ...main,
        itemDetail: {
          ...main.itemDetail,
          quantity: main.itemDetail.quantity - 1
        }
      });
    }
  };

  const handleUpdateItemDetailQuantity = (
    event: ChangeEvent<HTMLInputElement>,
    format: 'blur' | null = null
  ): void => {
    const {value} = event.target;
    const quantity =
      format === 'blur' && (value.length === 0 || value === '0')
        ? main.cart.items.some((cartItem): boolean => cartItem.id === main.itemDetail?.id)
          ? 0
          : 1
        : parseInt(value, 10);
    if (main.itemDetail !== null && main.itemDetail.quantity !== quantity) {
      setMain({
        ...main,
        itemDetail: {
          ...main.itemDetail,
          quantity: quantity
        }
      });
    }
  };

  const handleIncrementItemDetailQuantity = (): void => {
    if (main.itemDetail !== null && main.itemDetail.quantity < 999) {
      setMain({
        ...main,
        itemDetail: {
          ...main.itemDetail,
          quantity: main.itemDetail.quantity + 1
        }
      });
    }
  };

  const handleUpdateCart = (itemDetail: typeof main.itemDetail): void => {
    if (itemDetail !== null) {
      setMain({
        ...main,
        itemDetail: null,
        cart: {
          ...main.cart,
          items: main.cart.items.some((cartItem): boolean => cartItem.id === itemDetail.id)
            ? itemDetail.quantity === 0
              ? main.cart.items.filter((cartItem): boolean => cartItem.id !== itemDetail.id)
              : main.cart.items.map((cartItem): typeof cartItem =>
                  cartItem.id === itemDetail.id ? {...cartItem, quantity: itemDetail.quantity} : cartItem
                )
            : [
                ...main.cart.items,
                {
                  id: itemDetail.id,
                  quantity: itemDetail.quantity
                }
              ]
        }
      });
    }
  };

  const handleToggleViewCart = (isVisible: boolean): void => {
    if (main.cart.isVisible !== isVisible) {
      setMain({
        ...main,
        cart: {
          ...main.cart,
          isVisible: isVisible,
          ...(!isVisible && {isViewing: false})
        }
      });
    }
  };

  const handleToggleConfirmClearCart = (isVisible: boolean): void => {
    if (main.dialog.clearCart.isVisible !== isVisible) {
      setMain({
        ...main,
        dialog: {
          ...main.dialog,
          clearCart: {
            ...main.dialog.clearCart,
            isVisible: isVisible
          }
        }
      });
    }
  };

  const handleClearCart = (): void => {
    setMain({
      ...main,
      ...(main.cart.items.length !== 0 && {cart: {...main.cart, items: []}}),
      dialog: {
        ...main.dialog,
        clearCart: {
          ...main.dialog.clearCart,
          isVisible: false
        }
      }
    });
  };

  const handleToggleConfirmShipping = (field: 'delivery' | 'name' | 'address', isVisible: boolean): void => {
    setMain({
      ...main,
      dialog: {
        ...main.dialog,
        shipping: {
          ...main.dialog.shipping,
          [field]: {
            ...main.dialog.shipping[field],
            isVisible: isVisible,
            ...(isVisible && {value: main.cart.shipping[field]})
          }
        }
      }
    });
  };

  const handleSelectShippingDelivery = (event: ChangeEvent<HTMLInputElement>): void => {
    const {value} = event.target;
    if (main.dialog.shipping.delivery.value !== value) {
      setMain({
        ...main,
        dialog: {
          ...main.dialog,
          shipping: {
            ...main.dialog.shipping,
            delivery: {
              ...main.dialog.shipping.delivery,
              value: value as typeof main.dialog.shipping.delivery.value
            }
          }
        }
      });
    }
  };

  const handleUpdateShippingName = (
    event: ChangeEvent<HTMLInputElement>,
    format: 'blur' | null = null
  ): void => {
    const {value} = event.target;
    const updatedValue =
      format === 'blur' ? toTitleCase(value.replace(/[^a-zA-Z\s]/g, '').slice(0, 50)) : value;
    if (main.dialog.shipping.name.value !== updatedValue) {
      setMain({
        ...main,
        dialog: {
          ...main.dialog,
          shipping: {
            ...main.dialog.shipping,
            name: {
              ...main.dialog.shipping.name,
              value: updatedValue
            }
          }
        }
      });
    }
  };

  const handleUpdateShippingAddress = (
    event: ChangeEvent<HTMLTextAreaElement>,
    format: 'blur' | null = null
  ): void => {
    const {value} = event.target;
    const updatedValue = format === 'blur' ? toTitleCase(value.replace(/[^a-zA-Z0-9.,\s]/g, ' ')) : value;
    if (main.dialog.shipping.address.value !== updatedValue) {
      setMain({
        ...main,
        dialog: {
          ...main.dialog,
          shipping: {
            ...main.dialog.shipping,
            address: {
              ...main.dialog.shipping.address,
              value: updatedValue
            }
          }
        }
      });
    }
  };

  const handleSaveShipping = (field: 'delivery' | 'name' | 'address'): void => {
    setMain({
      ...main,
      ...(main.cart.shipping[field] !== main.dialog.shipping[field].value && {
        cart: {
          ...main.cart,
          shipping: {
            ...main.cart.shipping,
            [field]: main.dialog.shipping[field].value
          }
        }
      }),
      dialog: {
        ...main.dialog,
        shipping: {
          ...main.dialog.shipping,
          [field]: {
            isVisible: false
          }
        }
      }
    });
  };

  const handleOrder = (): void => {
    window.open('https://r.grab.com/g/2-1-6-C65WL351CXXDCA', '_blank', 'noopener,noreferrer');
  };

  const handleCheckout = (): void => {
    if (
      main.cart.items.length !== 0 &&
      main.cart.shipping.name.length !== 0 &&
      main.cart.shipping.address.length !== 0
    ) {
      const cartItems = main.cart.items
        .map(
          (cartItem): string => `${cartItem.quantity}x ${translate(`item.${cartItem.id.toLowerCase()}.name`)}`
        )
        .join('\n');
      const message = translate('cart.submit.message', {
        name: main.cart.shipping.name.toUpperCase(),
        address: main.cart.shipping.address,
        delivery: translate(`dialog.shippingdelivery.option.${main.cart.shipping.delivery?.toLowerCase()}`),
        items: cartItems
      });
      const url = `${socialMedias.find((socialMedia): boolean => socialMedia.name === 'whatsapp')?.url}?text=${encodeURIComponent(message)}`;
      window.open(url, '_blank', 'noopener,noreferrer');
    }
  };

  useEffect((): (() => void) => {
    const itemsDiv = itemsDivRef.current;
    if (itemsDiv) {
      itemsDiv.addEventListener('scroll', handleUpdateScroll);
    }
    if (!googleAnalytics.isInitialized) {
      googleAnalytics.initialize(process.env.REACT_APP_GA_MEASUREMENT_ID);
      googleAnalytics.send('pageview');
    }
    return (): void => {
      itemsDiv?.removeEventListener('scroll', handleUpdateScroll);
    };
  }, []);

  useEffect((): void => {
    i18next.changeLanguage(language);
    setMain({
      ...main,
      dialog: {
        ...main.dialog,
        language: {
          ...main.dialog.language,
          value: language
        }
      }
    });
  }, [language]);

  useEffect((): void => {
    handleUpdateScroll();
  }, [main.search, main.category]);

  useEffect((): void => {
    const fetchCartShipping = async (): Promise<void> => {
      const cart = sessionStorage.getItem('reshare:flavors:cart');
      let delivery = null;
      let name = '';
      let address = '';
      if (cart !== null) {
        const key = await importKey('reshare:flavors:key');
        if (key === null) {
          sessionStorage.removeItem('reshare:flavors:cart');
        } else {
          const encryptedShipping = JSON.parse(cart).shipping as {encrypted: string; iv: string};
          const decryptedShipping = await decrypt(key, encryptedShipping.encrypted, encryptedShipping.iv);
          const {
            delivery: shippingDelivery,
            name: shippingName,
            address: shippingAddress
          } = JSON.parse(decryptedShipping) as typeof main.cart.shipping;
          delivery = shippingDelivery;
          name = shippingName;
          address = shippingAddress;
        }
      }
      setMain({
        ...main,
        cart: {
          ...main.cart,
          shipping: {
            ...main.cart.shipping,
            delivery: delivery,
            name: name,
            address: address
          }
        }
      });
    };
    fetchCartShipping();
  }, [main.cart.isVisible]);

  useEffect((): void => {
    localStorage.setItem(
      'reshare:flavors:cart',
      JSON.stringify({
        items: main.cart.items,
        timestamp: DateTime.utc().toISO()
      })
    );
    setMain({
      ...main,
      cart: {
        ...main.cart,
        ...(main.cart.items.length === 0 && {isVisible: false, isViewing: false}),
        billing: {
          ...main.cart.billing,
          subtotal: main.cart.items.reduce(
            (subtotal, cartItem): number =>
              subtotal +
              (items.find((item): boolean => item.id === cartItem.id)?.price ?? 0) * cartItem.quantity,
            0
          ),
          total: main.cart.items.reduce(
            (total, cartItem): number =>
              total +
              (items.find((item): boolean => item.id === cartItem.id)?.price ?? 0) * cartItem.quantity,
            0
          )
        }
      }
    });
  }, [main.cart.items]);

  useEffect((): void => {
    const saveCartShipping = async (): Promise<void> => {
      let key = await importKey('reshare:flavors:key');
      if (key === null) {
        key = await generateKey();
        await exportKey('reshare:flavors:key', key);
      }
      const encryptedShipping = await encrypt(JSON.stringify(main.cart.shipping), key);
      sessionStorage.setItem('reshare:flavors:cart', JSON.stringify({shipping: encryptedShipping}));
    };
    saveCartShipping();
  }, [main.cart.shipping]);

  return (
    <>
      <Seo
        title={translate('metadata.title')}
        description={translate('metadata.description')}
        favicon='favicons/flavors.ico'
      />
      <Transition show={main.itemDetail === null && !main.cart.isVisible}>
        <section
          className={`space-y-3 overflow-x-hidden px-5 py-8 duration-300 data-[closed]:-translate-x-full data-[closed]:opacity-0 md:mx-auto md:w-4/5 md:space-y-4 md:px-0 md:py-12 lg:grid lg:h-dvh lg:w-5/6 lg:grid-cols-2 lg:gap-4 lg:space-y-0 lg:py-10 ${main.cart.items.length === 0 ? '' : 'pb-32 md:pb-36 lg:pb-32'}`.trim()}
        >
          <div className='lg:space-y-3'>
            {new Set(banners.map((banner): string => banner.name.split('-')[0] ?? '')).size === 1 && (
              <img
                className={`pointer-events-none w-full select-none rounded-xl ${shouldAnimate ? 'animate-fade-down animate-duration-500' : ''}`.trim()}
                src={banners.find((banner): boolean => banner.name.endsWith(`-${language}`))?.src}
                alt={translate(
                  `banner.alt.${banners
                    .find((banner): boolean => banner.name.endsWith(`-${language}`))
                    ?.name.split('-')[0]
                    ?.toLowerCase()}`
                )}
              />
            )}
            {new Set(banners.map((banner): string => banner.name.split('-')[0] ?? '')).size > 1 && (
              <Carousel
                autoPlay={true}
                infiniteLoop={true}
                emulateTouch={true}
                stopOnHover={true}
                showStatus={false}
                showArrows={false}
                showThumbs={false}
                interval={8000}
                transitionTime={750}
              >
                {banners
                  .filter((banner): boolean => banner.name.endsWith(`-${language}`))
                  .map(
                    (banner): JSX.Element => (
                      <img
                        className={`pointer-events-none h-full w-full select-none rounded-xl ${shouldAnimate ? 'animate-fade-down animate-duration-500' : ''}`.trim()}
                        key={banner.name.toLowerCase()}
                        src={banner.src}
                        alt={translate(`banner.alt.${banner.name.split('-')[0]?.toLowerCase()}`)}
                      />
                    )
                  )}
              </Carousel>
            )}
            {viewport.width >= 1024 && (
              <div className='grid grid-cols-2 gap-2'>
                <div className='flex'>
                  {socialMedias.map(
                    (socialMedia, index): JSX.Element => (
                      <Button
                        className={`group !py-2 font-semibold ${socialMedia.color} ${index === 0 ? 'rounded-r-none' : index === socialMedias.length - 1 ? 'rounded-l-none' : '!rounded-none'} ${shouldAnimate ? 'animate-fade-up animate-duration-500' : ''}`.trim()}
                        style={{animationDelay: `${index * 100}ms`}}
                        key={socialMedia.name.toLowerCase()}
                        onClick={(): void => {
                          window.open(socialMedia.url, '_blank', 'noopener,noreferrer');
                        }}
                      >
                        <FontAwesomeIcon
                          className='text-base group-hover:animate-wiggle-more group-hover:animate-duration-500 group-hover:animate-infinite'
                          icon={socialMedia.icon}
                        />
                      </Button>
                    )
                  )}
                </div>
                <footer
                  className={`flex items-center justify-center rounded-xl bg-[#F7E7D0] text-center text-[0.625rem] font-semibold text-[#7C420B] ${shouldAnimate ? 'animate-fade-up animate-duration-500' : ''}`.trim()}
                >
                  {translate('copyright', {year: DateTime.utc().toLocal().year})}
                </footer>
              </div>
            )}
          </div>
          <div className='space-y-3 md:space-y-4 lg:space-y-3 lg:overflow-hidden'>
            <div className='flex items-center justify-between space-x-2'>
              <div
                className={`relative w-full ${shouldAnimate ? 'animate-fade-right animate-duration-500 lg:animate-fade lg:animate-duration-1000' : ''}`.trim()}
                onAnimationEnd={(): void => {
                  handleDisableAnimations();
                }}
              >
                <FontAwesomeIcon
                  className='absolute left-4 top-1/2 -translate-y-1/2 text-gray-400'
                  icon={faSearch}
                />
                <Input
                  className='border-gray-100 !pl-10 duration-100 focus:border-default'
                  name='search'
                  placeholder={translate('search.placeholder')}
                  value={main.search}
                  onChange={(event): void => {
                    handleUpdateSearch(event);
                  }}
                  onBlur={(event): void => {
                    handleUpdateSearch(event, 'blur');
                  }}
                />
              </div>
              <Button
                className={`!w-fit bg-transparent !p-0 duration-150 active:scale-95 active:bg-transparent ${shouldAnimate ? 'animate-fade-left animate-duration-500' : ''}`.trim()}
                onClick={(): void => {
                  handleToggleConfirmLanguage(true);
                }}
              >
                <ReactCountryFlag
                  className='pointer-events-none select-none'
                  style={{
                    boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                    height:
                      viewport.width < 768
                        ? '2rem'
                        : viewport.width >= 768 && viewport.width < 1024
                          ? '2.25rem'
                          : '1.75rem',
                    width: 'auto'
                  }}
                  countryCode={languages.find((lang): boolean => lang.id === language)?.country ?? 'ID'}
                  svg={true}
                  alt={translate(
                    `language.alt.${languages.find((lang): boolean => lang.id === language)?.id.toLowerCase()}flag`
                  )}
                />
              </Button>
            </div>
            <div
              className={`relative grid grid-cols-4 rounded-xl bg-slate-100 ${shouldAnimate ? 'animate-fade animate-duration-1000' : ''}`.trim()}
            >
              <div
                className='absolute left-0 top-0 h-full w-1/4 rounded-xl bg-default transition-all duration-300'
                style={{transform: `translateX(${categories.indexOf(main.category) * 100}%)`}}
              />
              {categories.map(
                (category): JSX.Element => (
                  <div
                    className='relative flex cursor-pointer items-center justify-center px-1 py-3 text-center text-[0.6875rem] font-bold transition-all duration-300 md:text-xs lg:py-2 lg:text-[0.625rem]'
                    key={category}
                    onClick={(): void => {
                      handleSelectCategory(category as typeof main.category);
                    }}
                  >
                    <span
                      className={`transition-colors duration-300 ${category === main.category ? 'text-white' : 'text-gray-600'}`}
                    >
                      {translate(`category.${category.toLowerCase()}`).toUpperCase()}
                    </span>
                  </div>
                )
              )}
            </div>
            <div
              className='grid grid-cols-2 gap-2 md:grid-cols-4 lg:relative lg:max-h-full lg:overflow-y-auto lg:overflow-x-hidden lg:pb-[6rem]'
              style={{scrollbarWidth: 'none'}}
              ref={itemsDivRef}
            >
              {viewport.width >= 1024 && !itemScroll.isAtTop && (
                <div className='pointer-events-none sticky top-0 z-10 col-span-4 h-6 w-full -translate-y-1 select-none bg-gradient-to-b from-black/40 to-transparent'></div>
              )}
              {!items.some(
                (item): boolean =>
                  (main.search.trim().length === 0 && main.category === 'all') ||
                  (main.search.trim().length !== 0 &&
                    new RegExp(
                      main.search
                        .trim()
                        .replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
                        .toLowerCase()
                    ).test(
                      `${translate(`item.${item.id.toLowerCase()}.name`)} ${translate(`item.${item.id.toLowerCase()}.keyword`)} ${translate(`item.${item.id.toLowerCase()}.highlight`)} ${item.price}`.toLowerCase()
                    )) ||
                  (main.category !== 'all' && item.category === main.category)
              ) && (
                <div
                  className={`col-span-2 rounded-xl border-2 border-gray-100 bg-gray-50 p-3 text-center text-sm leading-6 text-gray-600 md:col-span-4 lg:relative lg:h-fit lg:text-xs lg:leading-6 ${shouldAnimate ? 'animate-fade animate-duration-500' : ''}`.trim()}
                >
                  <p
                    dangerouslySetInnerHTML={{__html: translate('search.notfound').replace(/\n/g, '<br />')}}
                  />
                </div>
              )}
              {items.map(
                (item, index): JSX.Element => (
                  <div
                    className={`flex flex-shrink cursor-pointer flex-col rounded-xl border-2 border-gray-100 duration-300 active:border-default lg:relative lg:max-h-60 ${
                      (main.search.trim().length === 0 && main.category === 'all') ||
                      (main.search.trim().length !== 0 &&
                        new RegExp(
                          main.search
                            .trim()
                            .replace(/[.*+?^${}()|[\]\\]/g, '\\$&')
                            .toLowerCase()
                        ).test(
                          `${translate(`item.${item.id.toLowerCase()}.name`)} ${translate(`item.${item.id.toLowerCase()}.keyword`)} ${translate(`item.${item.id.toLowerCase()}.highlight`)} ${item.price}`.toLowerCase()
                        )) ||
                      (main.category !== 'all' && item.category === main.category)
                        ? 'visible'
                        : 'hidden'
                    } ${shouldAnimate ? 'animate-fade-up animate-duration-500' : isMobile ? '' : 'lg:hover:border-default'}`.trim()}
                    style={{animationDelay: `${index * 100}ms`}}
                    key={item.id.toLowerCase()}
                    onClick={(): void => {
                      handleToggleViewItemDetail(item.id);
                    }}
                  >
                    <div className='h-28 overflow-hidden rounded-t-xl'>
                      <img
                        className='pointer-events-none h-full w-full select-none object-cover object-center'
                        src={item.image}
                        alt={translate(`item.${item.id.toLowerCase()}.alt`)}
                      />
                    </div>
                    <div className='flex flex-grow flex-col text-xs lg:text-[0.625rem]'>
                      <div className='p-3 !pb-1 font-bold text-gray-600 lg:p-2'>
                        {translate(`item.${item.id.toLowerCase()}.name`).toUpperCase()}
                      </div>
                      <div className='flex-grow p-3 !pt-0 text-gray-500 lg:p-2 lg:pb-2'>
                        {translate(`item.${item.id.toLowerCase()}.highlight`)}
                      </div>
                      <div className='w-fit place-self-end rounded-br-lg rounded-tl-xl bg-default px-2 py-1 font-semibold italic text-white lg:px-2 lg:text-[0.6875rem]'>
                        {`${language === 'id' ? '' : 'IDR'} ${item.price.toLocaleString('id-ID')}`.trim()}
                      </div>
                    </div>
                  </div>
                )
              )}
              {viewport.width >= 1024 && !itemScroll.isAtBottom && (
                <div className='pointer-events-none sticky bottom-0 z-10 col-span-4 h-6 w-full translate-y-1 select-none bg-gradient-to-t from-black/40 to-transparent'></div>
              )}
            </div>
            {viewport.width < 1024 && (
              <div className='space-y-3 md:grid md:grid-cols-2 md:gap-2 md:space-y-0 lg:hidden'>
                <div
                  className={`flex ${shouldAnimate ? 'md:animate-fade-right md:animate-duration-500' : ''}`.trim()}
                >
                  {socialMedias.map(
                    (socialMedia, index): JSX.Element => (
                      <Button
                        className={`group font-semibold duration-300 active:scale-110 active:shadow-md md:active:scale-100 md:active:shadow-none ${socialMedia.color} ${index === 0 ? 'rounded-r-none' : index === socialMedias.length - 1 ? 'rounded-l-none' : '!rounded-none'} ${shouldAnimate ? 'animate-fade-left animate-duration-500 md:animate-none' : ''}`.trim()}
                        style={{animationDelay: `${index * 100}ms`}}
                        key={socialMedia.name.toLowerCase()}
                        onClick={(): void => {
                          window.open(socialMedia.url, '_blank', 'noopener,noreferrer');
                        }}
                      >
                        <FontAwesomeIcon
                          className='text-lg group-active:animate-wiggle-more group-active:animate-duration-500 group-active:animate-infinite'
                          icon={socialMedia.icon}
                        />
                      </Button>
                    )
                  )}
                </div>
                <footer
                  className={`rounded-xl bg-[#F7E7D0] p-3 text-center text-xs font-semibold text-[#7C420B] md:px-0 md:text-xs ${shouldAnimate ? 'animate-fade-right animate-duration-500 md:animate-fade-left' : ''}`.trim()}
                >
                  {translate('copyright', {year: DateTime.utc().toLocal().year})}
                </footer>
              </div>
            )}
          </div>
        </section>
        {main.cart.items.length !== 0 && !main.dialog.language.isVisible && (
          <div
            className={`fixed bottom-0 z-10 w-full rounded-t-xl bg-white px-5 pb-8 pt-6 [box-shadow:0rem_-0.625rem_1.25rem_-0.3125rem_rgba(0,0,0,0.3)] md:px-0 ${shouldAnimate ? 'animate-fade-up animate-duration-500' : ''}`.trim()}
          >
            <Button
              className='justify-between md:mx-auto md:w-3/4 lg:w-2/5'
              onClick={(): void => {
                handleToggleViewCart(true);
              }}
            >
              <div className='flex items-center justify-center space-x-2'>
                <FontAwesomeIcon className='text-lg' icon={faCartShopping} />
                <span>{translate('cart.button.label')}</span>
                <span className='font-normal'>{`(${translate('cart.button.itemcount', {count: main.cart.items.length})}${main.cart.items.length > 1 && language === 'en' ? 's' : ''})`}</span>
              </div>
              <div>{`${language === 'id' ? '' : 'IDR'} ${main.cart.billing.subtotal.toLocaleString('id-ID')}`}</div>
            </Button>
          </div>
        )}
      </Transition>
      <Transition show={main.itemDetail !== null}>
        <section className='sticky bottom-0 flex h-dvh flex-col overflow-hidden bg-white duration-300 ease-linear data-[closed]:translate-x-full data-[closed]:opacity-0'>
          <Button
            className='absolute left-5 top-8 z-10 !h-9 !w-9 bg-white !text-gray-500 shadow-lg duration-150 active:scale-90 active:bg-white md:left-8 md:top-10 md:!h-14 md:!w-14 lg:left-8 lg:top-8 lg:!h-10 lg:!w-10 lg:shadow-xl'
            onClick={(): void => {
              handleToggleViewItemDetail(null);
            }}
          >
            <FontAwesomeIcon className='text-lg md:text-2xl lg:text-lg' icon={faArrowLeft} />
          </Button>
          <div
            className={`flex flex-1 items-center justify-center overflow-hidden lg:items-stretch ${items.find((item): boolean => item.id === main.itemDetail?.id)?.style}`}
          >
            <img
              className={`pointer-events-none select-none ${items.find((item): boolean => item.id === main.itemDetail?.id)?.style}`.trim()}
              src={items.find((item): boolean => item.id === main.itemDetail?.id)?.image}
              alt={main.itemDetail === null ? '' : translate(`item.${main.itemDetail.id.toLowerCase()}.alt`)}
            />
          </div>
          <div className='pointer-events-none h-3 -translate-y-3 select-none rounded-t-xl bg-white md:h-4 lg:[box-shadow:0rem_-0.625rem_1.25rem_-0.3125rem_rgba(0,0,0,0.2)]'></div>
          <div className='z-10 space-y-6 bg-white px-5 pb-8 md:mx-auto md:w-3/4 md:px-0 md:pb-12 lg:w-2/5'>
            <div className='space-y-2 md:space-y-4 lg:space-y-3'>
              <div className='flex justify-between space-x-4 md:text-lg lg:text-base'>
                <h1 className='font-bold text-gray-600'>
                  {main.itemDetail === null
                    ? ''
                    : translate(`item.${main.itemDetail.id.toLowerCase()}.name`).toUpperCase()}
                </h1>
                <div className='text-nowrap font-semibold text-gray-400'>
                  {`${language === 'id' ? '' : 'IDR'} ${items.find((item): boolean => item.id === main.itemDetail?.id)?.price.toLocaleString('id-ID')}`.trim()}
                </div>
              </div>
              <p className='text-sm text-gray-500 md:text-base lg:text-sm'>
                {main.itemDetail === null
                  ? ''
                  : translate(`item.${main.itemDetail.id.toLowerCase()}.description`)}
              </p>
            </div>
            <div className='flex flex-col items-center justify-center space-y-4 md:space-y-6 lg:space-y-4'>
              <div className='flex h-8 justify-center md:h-10 lg:h-8'>
                <Button
                  className='group !h-8 !w-10 rounded-r-none border-y-2 border-l-2 border-gray-100 bg-white shadow-sm active:!bg-default md:!h-10 md:!w-12 lg:!h-8 lg:!w-10'
                  onClick={(): void => {
                    handleDecrementItemDetailQuantity();
                  }}
                >
                  <FontAwesomeIcon
                    className='text-gray-500 duration-150 group-active:text-white'
                    icon={faMinus}
                  />
                </Button>
                <Input
                  className='!w-1/5 !rounded-none border-none border-gray-100 bg-slate-100 text-center text-sm font-semibold text-gray-500 shadow-sm md:!w-1/4 md:text-base lg:text-xs'
                  inputMode='numeric'
                  value={
                    main.itemDetail === null
                      ? 1
                      : isNaN(main.itemDetail.quantity)
                        ? ''
                        : main.itemDetail.quantity
                  }
                  maxLength={3}
                  onChange={(event): void => {
                    handleUpdateItemDetailQuantity(event);
                  }}
                  onBlur={(event): void => {
                    handleUpdateItemDetailQuantity(event, 'blur');
                  }}
                />
                <Button
                  className='group !h-8 !w-10 rounded-l-none border-y-2 border-r-2 border-gray-100 bg-white shadow-sm active:!bg-default md:!h-10 md:!w-12 lg:!h-8 lg:!w-10'
                  onClick={(): void => {
                    handleIncrementItemDetailQuantity();
                  }}
                >
                  <FontAwesomeIcon
                    className='text-gray-500 duration-150 group-active:text-white'
                    icon={faPlus}
                  />
                </Button>
              </div>
              <Button
                className='space-x-2'
                onClick={(): void => {
                  handleUpdateCart(main.itemDetail);
                }}
              >
                <FontAwesomeIcon
                  className='text-lg'
                  icon={
                    main.itemDetail !== null &&
                    main.cart.items.some((cartItem): boolean => cartItem.id === main.itemDetail?.id) &&
                    (isNaN(main.itemDetail.quantity) || main.itemDetail.quantity === 0)
                      ? faTrashAlt
                      : faCartPlus
                  }
                />
                <span>
                  {main.itemDetail !== null &&
                  main.cart.items.some((cartItem): boolean => cartItem.id === main.itemDetail?.id) &&
                  (isNaN(main.itemDetail.quantity) || main.itemDetail.quantity === 0)
                    ? translate('cart.items.remove')
                    : `${translate(`cart.${main.cart.items.some((cartItem): boolean => cartItem.id === main.itemDetail?.id) && (main.itemDetail?.quantity ?? 0) > 0 ? 'items.update' : 'button.label'}`)} - ${
                        main.itemDetail === null
                          ? 0
                          : `${language === 'id' ? '' : 'IDR'} ${
                              isNaN(main.itemDetail.quantity)
                                ? 0
                                : Number(
                                    (items.find((item): boolean => item.id === main.itemDetail?.id)?.price ??
                                      0) * main.itemDetail.quantity
                                  ).toLocaleString('id-ID')
                            }`.trim()
                      }`}
                </span>
              </Button>
            </div>
          </div>
        </section>
      </Transition>
      <Transition show={main.cart.isVisible && main.itemDetail === null}>
        <section
          className={`sticky bottom-0 flex h-dvh flex-col overflow-hidden duration-300 ease-linear data-[closed]:opacity-0 ${main.cart.isViewing ? 'data-[closed]:-translate-x-full' : 'data-[closed]:translate-x-full'}`}
        >
          <div className='fixed top-0 w-full rounded-b-xl bg-default shadow-md'>
            <div className='flex items-center justify-between px-5 pb-3 pt-8 md:mx-auto md:w-3/4 md:px-0 lg:w-2/5'>
              <Button
                className='!h-9 !w-9 bg-white !text-gray-500 shadow-md duration-150 active:scale-90 active:bg-white md:!h-12 md:!w-12 lg:!h-9 lg:!w-9'
                onClick={(): void => {
                  handleToggleViewCart(false);
                }}
              >
                <FontAwesomeIcon className='text-lg md:text-2xl lg:text-lg' icon={faArrowLeft} />
              </Button>
              <div className='flex-1 text-center text-lg font-semibold tracking-wide text-white md:text-xl lg:text-lg'>
                {translate('cart.button.label')}
              </div>
              <Button
                className='!h-9 !w-9 bg-white !text-gray-500 shadow-md duration-150 active:scale-90 active:bg-white md:!h-12 md:!w-12 lg:!h-9 lg:!w-9'
                onClick={(): void => {
                  handleToggleConfirmClearCart(true);
                }}
              >
                <FontAwesomeIcon className='text-lg md:text-2xl lg:text-lg' icon={faTrashAlt} />
              </Button>
            </div>
          </div>
          <div className='mt-20 h-dvh flex-1 space-y-2 overflow-y-auto p-5 md:mx-auto md:mt-24 md:w-3/4 md:px-0 lg:mt-20 lg:w-2/5'>
            {main.cart.items.map(
              (cartItem): JSX.Element => (
                <div
                  className='flex cursor-pointer items-center justify-between space-x-2 rounded-xl border-2 border-b-0 border-gray-50 p-2 text-xs shadow-md'
                  key={cartItem.id}
                  onClick={(): void => {
                    handleToggleViewItemDetail(cartItem.id);
                  }}
                >
                  <div className='flex h-12 w-12 items-center justify-center rounded-xl bg-default text-sm font-bold text-white'>{`${cartItem.quantity}x`}</div>
                  <div className='flex-1 text-gray-600'>
                    <div className='font-semibold'>{translate(`item.${cartItem.id.toLowerCase()}.name`)}</div>
                    <div className='font-semibold italic text-default'>{translate('cart.items.edit')}</div>
                  </div>
                  <div className='text-gray-700'>
                    {`${language === 'id' ? '' : 'IDR'} ${((items.find((item): boolean => item.id === cartItem.id)?.price ?? 0) * cartItem.quantity).toLocaleString('id-ID')}`.trim()}
                  </div>
                </div>
              )
            )}
          </div>
          <div className='space-y-4 rounded-t-xl p-5 pb-8 [box-shadow:0rem_-0.625rem_1.25rem_-0.3125rem_rgba(0,0,0,0.1)] md:px-0'>
            <div className='space-y-3 text-sm'>
              <div className='flex items-center justify-between md:mx-auto md:w-3/4 lg:w-2/5'>
                <div className='text-gray-600'>{translate('cart.shipping.delivery')}</div>
                <div
                  className={`flex cursor-pointer items-center justify-center space-x-2 text-gray-500 duration-150 active:scale-95 ${main.cart.shipping.delivery === null ? 'animate-jump animate-duration-1000 animate-infinite' : ''}`.trim()}
                  onClick={(): void => {
                    handleToggleConfirmShipping('delivery', true);
                  }}
                >
                  <span className={main.cart.shipping.delivery === null ? 'text-gray-400' : 'text-gray-600'}>
                    {main.cart.shipping.delivery === null
                      ? translate('cart.shipping.choose')
                      : translate(
                          `dialog.shippingdelivery.option.${main.cart.shipping.delivery.toLowerCase()}`
                        )}
                  </span>
                  <FontAwesomeIcon icon={faEdit} />
                </div>
              </div>
              {['grabexpress', 'gosend'].includes(main.cart.shipping.delivery ?? '') && (
                <>
                  <div className='flex items-center justify-between md:mx-auto md:w-3/4 lg:w-2/5'>
                    <div className='text-gray-600'>{translate('cart.shipping.name')}</div>
                    <div
                      className={`flex cursor-pointer items-center justify-center space-x-2 text-gray-500 duration-150 active:scale-95 ${main.cart.shipping.name.length === 0 ? 'animate-jump animate-duration-1000 animate-infinite' : ''}`.trim()}
                      onClick={(): void => {
                        handleToggleConfirmShipping('name', true);
                      }}
                    >
                      <span
                        className={main.cart.shipping.name.length === 0 ? 'text-gray-400' : 'text-gray-600'}
                      >
                        {main.cart.shipping.name.length === 0
                          ? translate('cart.shipping.fill')
                          : main.cart.shipping.name
                              .split(' ')
                              .reduce(
                                (name, word): string =>
                                  name.length + word.length + 1 < 25 ? `${name} ${word}` : name,
                                ''
                              )}
                      </span>
                      <FontAwesomeIcon icon={faEdit} />
                    </div>
                  </div>
                  <div className='flex items-center justify-between md:mx-auto md:w-3/4 lg:w-2/5'>
                    <div className='text-gray-600'>{translate('cart.shipping.address')}</div>
                    <div
                      className={`flex cursor-pointer items-center justify-center space-x-2 text-gray-500 duration-150 active:scale-95 ${main.cart.shipping.address.length === 0 ? 'animate-jump animate-duration-1000 animate-infinite' : ''}`.trim()}
                      onClick={(): void => {
                        handleToggleConfirmShipping('address', true);
                      }}
                    >
                      <span
                        className={
                          main.cart.shipping.address.length === 0 ? 'text-gray-400' : 'text-gray-600'
                        }
                      >
                        {main.cart.shipping.address.length === 0
                          ? translate('cart.shipping.fill')
                          : (main.cart.shipping.address
                              .split(' ')
                              .reduce(
                                (address, word): string =>
                                  address.length + word.length + 1 < 25 ? `${address} ${word}` : address,
                                ''
                              )
                              .split(',')[0] ?? '')}
                      </span>
                      <FontAwesomeIcon icon={faEdit} />
                    </div>
                  </div>
                </>
              )}
            </div>
            <hr />
            <div className='space-y-2 text-sm'>
              <div className='flex items-center justify-between text-gray-600 md:mx-auto md:w-3/4 lg:w-2/5'>
                <div>{translate('cart.billing.subtotal')}</div>
                <div>
                  {`${language === 'id' ? '' : 'IDR'} ${main.cart.billing.subtotal.toLocaleString('id-ID')}`.trim()}
                </div>
              </div>
              <div className='flex items-center justify-between font-bold text-gray-800 md:mx-auto md:w-3/4 lg:w-2/5'>
                <div>{translate('cart.billing.total')}</div>
                <div>
                  {`${language === 'id' ? '' : 'IDR'} ${main.cart.billing.total.toLocaleString('id-ID')}`.trim()}
                </div>
              </div>
              {['grabexpress', 'gosend'].includes(main.cart.shipping.delivery ?? '') && (
                <p className='mx-auto w-5/6 text-center text-sm text-gray-400 md:w-3/4 lg:w-2/5 lg:text-xs'>
                  {translate('cart.billing.disclaimer')}
                </p>
              )}
            </div>
            {main.cart.shipping.delivery !== null &&
              (main.cart.shipping.delivery === 'grabfood' ||
                (['grabexpress', 'gosend'].includes(main.cart.shipping.delivery) &&
                  main.cart.shipping.name.length !== 0 &&
                  main.cart.shipping.address.length !== 0)) && (
                <Button
                  className='space-x-2 md:mx-auto md:w-3/4 lg:w-2/5'
                  onClick={(): void => {
                    if (main.cart.shipping.delivery === 'grabfood') {
                      handleOrder();
                    } else {
                      handleCheckout();
                    }
                  }}
                >
                  <FontAwesomeIcon className='text-lg' icon={faBagShopping} />
                  <span>
                    {translate(
                      `cart.submit.${main.cart.shipping.delivery === 'grabfood' ? 'order' : 'checkout'}`
                    )}
                  </span>
                </Button>
              )}
          </div>
        </section>
      </Transition>
      <Dialog
        isVisible={main.dialog.language.isVisible}
        title={translate('dialog.language.title')}
        icon={faSave}
        confirm={translate('dialog.language.confirm')}
        onClose={(): void => {
          handleToggleConfirmLanguage(false);
        }}
        onConfirm={(): void => {
          handleSaveLanguage();
        }}
      >
        <fieldset className='flex flex-col space-y-2'>
          {languages.map(
            (lang): JSX.Element => (
              <Input
                key={lang.id.toLowerCase()}
                name={lang.id.toLowerCase()}
                type='radio'
                icon={
                  <ReactCountryFlag
                    className='scale-90'
                    style={{
                      boxShadow: '0px 4px 6px rgba(0, 0, 0, 0.1)',
                      width: '1.875rem',
                      height: 'auto'
                    }}
                    countryCode={lang.country}
                    svg={true}
                  />
                }
                label={translate(`dialog.language.option.${lang.name.toLowerCase()}`)}
                value={lang.id}
                isChecked={lang.id === main.dialog.language.value}
                onChange={(event): void => {
                  handleSelectLanguage(event);
                }}
              />
            )
          )}
        </fieldset>
      </Dialog>
      <Dialog
        isVisible={main.dialog.clearCart.isVisible}
        title={translate('dialog.clearcart.title')}
        icon={faTrashAlt}
        confirm={translate('dialog.clearcart.confirm')}
        onClose={(): void => {
          handleToggleConfirmClearCart(false);
        }}
        onConfirm={(): void => {
          handleClearCart();
        }}
      >
        <p className='pb-1 text-sm text-gray-600'>{translate('dialog.clearcart.subtitle')}</p>
      </Dialog>
      <Dialog
        isVisible={main.dialog.shipping.delivery.isVisible}
        title={translate('dialog.shippingdelivery.title')}
        icon={faSave}
        confirm={translate('dialog.shippingdelivery.confirm')}
        onClose={(): void => {
          handleToggleConfirmShipping('delivery', false);
        }}
        onConfirm={(): void => {
          handleSaveShipping('delivery');
        }}
      >
        <fieldset className='flex flex-col space-y-2'>
          {deliveries.map(
            (delivery): JSX.Element => (
              <Input
                key={delivery.name}
                name={delivery.name}
                type='radio'
                icon={<img className='w-5' src={delivery.icon} />}
                label={translate(`dialog.shippingdelivery.option.${delivery.name.toLowerCase()}`)}
                value={delivery.name}
                isChecked={delivery.name === main.dialog.shipping.delivery.value}
                onChange={(event): void => {
                  handleSelectShippingDelivery(event);
                }}
              />
            )
          )}
        </fieldset>
      </Dialog>
      <Dialog
        isVisible={main.dialog.shipping.name.isVisible}
        title={translate('dialog.shippingname.title')}
        icon={faSave}
        confirm={translate('dialog.shippingname.confirm')}
        onClose={(): void => {
          handleToggleConfirmShipping('name', false);
        }}
        onConfirm={(): void => {
          handleSaveShipping('name');
        }}
      >
        <div className='space-y-2'>
          <Input
            placeholder={translate('dialog.shippingname.input.name.placeholder')}
            value={main.dialog.shipping.name.value}
            maxLength={50}
            onChange={(event): void => {
              handleUpdateShippingName(event);
            }}
            onBlur={(event): void => {
              handleUpdateShippingName(event, 'blur');
            }}
          />
        </div>
      </Dialog>
      <Dialog
        isVisible={main.dialog.shipping.address.isVisible}
        title={translate('dialog.shippingaddress.title')}
        icon={faSave}
        confirm={translate('dialog.shippingaddress.confirm')}
        onClose={(): void => {
          handleToggleConfirmShipping('address', false);
        }}
        onConfirm={(): void => {
          handleSaveShipping('address');
        }}
      >
        <div className='space-y-2'>
          <TextArea
            placeholder={translate('dialog.shippingaddress.input.address.placeholder')}
            value={main.dialog.shipping.address.value}
            maxLength={255}
            row={5}
            onChange={(event): void => {
              handleUpdateShippingAddress(event);
            }}
            onBlur={(event): void => {
              handleUpdateShippingAddress(event, 'blur');
            }}
          />
        </div>
      </Dialog>
    </>
  );
};

export default Flavors;
