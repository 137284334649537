import {type EffectCallback, type JSX, type ReactNode, useEffect} from 'react';
import useApp from 'src/hooks/use-app';

type ViewportProps = {
  children?: ReactNode;
};

const debounce = (callback: (...args: unknown[]) => void, delay: number): EffectCallback => {
  let timerId: number | null = null;
  return (): void => {
    if (timerId !== null) {
      window.clearTimeout(timerId);
    }
    timerId = window.setTimeout(callback, delay);
  };
};

const Viewport = ({children}: ViewportProps): JSX.Element => {
  const {setViewport} = useApp();

  const handleResizeViewport = debounce((): void => {
    setViewport({width: window.innerWidth, height: window.innerHeight});
  }, 500);

  useEffect((): (() => void) => {
    handleResizeViewport();
    window.addEventListener('resize', handleResizeViewport);
    return (): void => {
      window.removeEventListener('resize', handleResizeViewport);
    };
  }, []);

  return <>{children}</>;
};

export default Viewport;
