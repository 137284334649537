import type {ChangeEventHandler, FocusEventHandler, JSX} from 'react';

type TextAreaProps = {
  className?: string;
  name?: string;
  placeholder?: string;
  value?: string;
  maxLength?: number;
  row?: number;
  onChange?: ChangeEventHandler<HTMLTextAreaElement>;
  onBlur?: FocusEventHandler<HTMLTextAreaElement>;
};

const TextArea = ({
  className = '',
  name,
  placeholder,
  value,
  maxLength,
  row,
  onChange,
  onBlur
}: TextAreaProps): JSX.Element => (
  <textarea
    className={`w-full resize-none rounded-xl border-2 border-gray-200 p-3 align-bottom text-sm outline-none disabled:bg-slate-100 disabled:opacity-100 md:text-base lg:text-xs ${className}`.trim()}
    id={name}
    name={name}
    placeholder={placeholder}
    maxLength={maxLength}
    rows={row}
    autoComplete='off'
    onChange={onChange}
    onBlur={onBlur}
  >
    {value}
  </textarea>
);

export default TextArea;
