import {Navigate, Route, Routes} from 'react-router-dom';
import Flavors from 'src/pages/flavors';
import type {JSX} from 'react';

const Router = (): JSX.Element => (
  <Routes>
    <Route path='/flavors' element={<Flavors />} />
    <Route path='*' element={<Navigate to='/flavors' />} />
  </Routes>
);

export default Router;
