import {
  type IsOnline,
  type Language,
  type Viewport,
  setIsOnline as setAppIsOnline,
  setLanguage as setAppLanguage,
  setViewport as setAppViewport
} from 'src/redux/reducers/app';
import {useDispatch, useSelector} from 'src/redux/hooks';

type UseApp = {
  isOnline: IsOnline;
  language: Language;
  viewport: Viewport;
  setIsOnline: (online: IsOnline) => void;
  setLanguage: (language: Language) => void;
  setViewport: (viewport: Viewport) => void;
};

const useApp = (): UseApp => {
  const dispatch = useDispatch();

  const setIsOnline = (isOnline: IsOnline): void => {
    dispatch(setAppIsOnline(isOnline));
  };

  const setLanguage = (language: Language): void => {
    dispatch(setAppLanguage(language));
  };

  const setViewport = (viewport: Viewport): void => {
    dispatch(setAppViewport(viewport));
  };

  return {
    isOnline: useSelector((state) => state.app.isOnline),
    language: useSelector((state) => state.app.language),
    viewport: useSelector((state) => state.app.viewport),
    setIsOnline: setIsOnline,
    setLanguage: setLanguage,
    setViewport: setViewport
  };
};

export default useApp;
