import type {CSSProperties, JSX, MouseEventHandler, ReactNode} from 'react';

type ButtonProps = {
  className?: string;
  style?: CSSProperties;
  type?: 'button' | 'submit';
  children?: ReactNode;
  onClick?: MouseEventHandler<HTMLButtonElement>;
};

const Button = ({
  className = '',
  style = {},
  type = 'button',
  children,
  onClick
}: ButtonProps): JSX.Element => (
  <button
    className={`flex w-full cursor-pointer select-none items-center justify-center rounded-xl bg-default p-3 text-sm font-bold text-white outline-none duration-150 active:bg-default-active md:text-base lg:text-xs lg:tracking-wide ${className}`.trim()}
    style={style}
    type={type}
    onClick={onClick}
  >
    {children}
  </button>
);

export default Button;
